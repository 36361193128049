import React, { useState, useEffect } from 'react'
// import { useParams } from 'react-router-dom'

import CheckoutInput from '../snippets/CheckoutInput'
import CheckoutContainer from '../layout/CheckoutContainer'
import CheckoutCheckbox from '../snippets/CheckoutCheckbox'
import CheckoutSummary from '../sections/CheckoutSummary'
import Accordeon from '../snippets/Accordeon'
import CheckoutPayButton from '../snippets/CheckoutPayButton'

import { getCart } from '../../utils/functions'

function Checkout() {
  const [cart, setCart] = useState(false)

  useEffect(() => {
    function get() {
      const cart = getCart()
      if (cart) {
        setCart(cart)
      }
    }
    get()

    window.addEventListener('cartUpdate', function() {
      get()
    })
  }, [])

  return (
    <div className='g9gqqf1 _1fragemqc _1fragemw8 _1fragempo _1fragemps'>
      <div className='cLGHj jeN3o _1fragemps _1fragempn _1fragemwg'>
        <div className='_1fragemwgone'>
          <header className='JPw09 REIwn KVQTQ _1fragemps'>
            Header
          </header>
          <div className='U6oc4'>

            <Accordeon id="ac1">

              {(cart && cart.items) &&
                <CheckoutSummary cart={cart} minimal={true} />
              }
              
            </Accordeon>

          </div>
          <div className='sooyq'>
            <div className='jeN3o _1fragemps _1fragempn _1fragemwg'>
              <main id="checkout-main" className='jvQbN'>
                <div className='_1ip0g651 _1fragemo1 _1fragem5z _1fragem4r _1fragem8c _1fragem74 _1fragem3c'>
                  <form action method='POST' navigate id="Form1" className='_1fragem2n'>

                    {/* Email form */}
                    <div>
                      <CheckoutContainer>
                        <section>
                          <div class="g3ArD">
                            <h2 class="n8k95w1 _1fragemo1 n8k95w2">Contact</h2>
                          </div>
                          <CheckoutInput index={0} label="Email or mobile phone number" type="email" />
                          <CheckoutCheckbox label="Email me with news and offers" />
                        </section>
                      </CheckoutContainer>
                    </div>

                    {/* Delivery form */}
                    <div>
                      <CheckoutContainer>
                        <section>
                          <div class="g3ArD">
                            <h2 class="n8k95w1 _1fragemo1 n8k95w2">Delivery</h2>
                          </div>
                          <CheckoutInput index={1} label="First name (optional)" type="text" />
                          <CheckoutInput index={2} label="Last name" type="text" />
                          <CheckoutInput index={3} label="Address" type="text" />
                          <CheckoutInput index={4} label="Apartment, suite, etc. (optional)" type="text" />
                          <CheckoutInput index={5} label="City" type="text" />
                          <CheckoutInput index={5} label="Postal code (optional)" type="text" />
                          <CheckoutCheckbox label="Save this information for next time" className="last" />
                          <div class="g3ArD">
                            <h2 class="n8k95w1 _1fragemo1 n8k95w2 _tlevel">Shipping method</h2>
                          </div>
                        </section>
                      </CheckoutContainer>
                    </div>


                    {/* Payment form */}
                    <div>
                      <CheckoutContainer>
                        <section>
                          <div class="g3ArD">
                            <h2 class="n8k95w1 _1fragemo1 n8k95w2">Payment</h2>
                            <p>All transactions are secure and encrypted.</p>
                          </div>
                        </section>
                      </CheckoutContainer>
                    </div>

                    {/* Sumary info */}

                    {(cart && cart.items) &&
                      <div class="g6jbm7">
                        <CheckoutContainer>
                          <section>
                            <div class="g3ArD">
                              <h2 class="n8k95w1 _1fragemo1 n8k95w2">Order summary ({cart.items.length})</h2>
                            </div>

                            <CheckoutSummary cart={cart} minimal={true} />
                          </section>

                          <CheckoutPayButton />
                        </CheckoutContainer>
                      </div>
                    }
                  </form>
                </div>
              </main>
              <footer role='contentinfo' className='mZR1U c0YIW _1fragemps'>
                <CheckoutContainer>
                  <div className='TfwLd'>
                    <button className='_1m2hr9ge _1fragemvz _1fragemo1 _1fragemq2 _1fragem32 _1fragemvf _1fragemvq _1fragemvu _1fragemvk _1m2hr9g18 _1fragemvq _1fragemvu _1fragemv9 _1fragemvh'>
                      <span class="_1m2hr9gr _1fragemvb _1fragemvt _1fragemvl _1fragemvu _1fragem2s _1fragemr1 _1fragemvd">Subscription policy</span>
                    </button>
                  </div>
                </CheckoutContainer>
              </footer>
            </div>
          </div>
        </div>
      </div>

      {(cart && cart.items) &&
        <div className='jCic4 _1fragemps _1fragempp _1fragemw8'>
          <CheckoutSummary cart={cart} minimal={true} />
        </div>
      }


    </div>
  )
}
export default Checkout