import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import { getAllProducts, addToCart, getCollection } from '../../utils/functions'
import VariantSelector from '../snippets/VariantSelector'
import Container from './../layout/Container'
import BreadCrumb from '../snippets/BreadCrumb'

function Product() {
  const location = useLocation()
  const { pathname } = location
  const handle = pathname.split('/products/')
  const selected = getAllProducts(window.Shopify.dm().sp().catalog()).find((item) => item.product_handle === handle[1])
  const collection = getCollection(window.Shopify.dm().sp().catalog(), handle[1])
  const [variantIndexSelected, setVariantIndexSelected] = useState(0)
  const [variantSelected, setVariantSelected] = useState(false)
  const [cart, setCart] = useState(false)

  useEffect(() => {
    const cart = localStorage.getItem('cart')

    if (cart) {
      setCart(JSON.parse(cart))
    }
  }, [])

  const add = () => {
    addToCart({
      id: new Date().valueOf(),
      final_price: variantSelected.price,
      featured_image: {
        url: "product.png"
      },
      price: variantSelected.price,
      product_title: "Product title",
      product_description: "hola mundo",
      product_id: variantSelected.product_id,
      variant_id: variantSelected.id,
      quantity: 1,
      properties: {},
      sku: "NATN",
      total_discount: 0,
      variant_title: "Default",
      vendor: "Permalink"
    })
    window.Shopify.reactive()
  }

  console.log("Product cart:", cart)

  return (
    <div className='content-for-layout'>

      <Container>
        <BreadCrumb
          linkText={collection.collection_name}
          linkUrl={`/collections/${collection.collection_handle}`}
          currentText={selected.product_name}
        />
      </Container>

      <Container>
        Product - {selected.product_name}
        <button onClick={add}>Add to cart</button>

        <VariantSelector
          item={selected}
          variantIndexSelected={variantIndexSelected}
          setVariantIndexSelected={setVariantIndexSelected}
          variantSelected={variantSelected}
          setVariantSelected={setVariantSelected}
        />
      </Container>

    </div>
  )
}
export default Product