
import React from 'react'

function CheckoutPayButton({id, children}) {

  return (
    <div class="_1ip0g652 _1fragemo1 _1fragem4g _1fragem6t _1fragem3c">
      <button 
        id="checkout-pay-button" 
        type="submit" 
        class="_1m2hr9ge">
          <span class="_19gi7yt0 _19gi7ytw _1fragemqe">Pay now</span>
      </button>
    </div>
  )
}
export default CheckoutPayButton