import React, { useState, useEffect } from 'react'

function VariantSelector({
  item,
  variantIndexSelected,
  setVariantIndexSelected,
  variantSelected,
  setVariantSelected
}) {
  const [option1, setOption1] = useState(null)
  const [option2, setOption2] = useState(null)
  const [option3, setOption3] = useState(null)

  useEffect(() => {
    if (item) {
      const sel = item.variants[variantIndexSelected]
      setVariantSelected(sel)
      setOption1(sel.option1)
      setOption2(sel.option2)
      setOption3(sel.option3)
    }

    
  }, [])

  const selVariant = (e) => {
    const target = e.target.getAttribute('data-option')
    const pos = e.target.getAttribute('data-option-num')
    if (pos === "1") setOption1(target)
    if (pos === "2") setOption2(target)
    if (pos === "3") setOption3(target)
  }

  const noAdd = () => {
    console.log("No se puede seleccionar!")
  }

  const isAvailable = (value, position) => {
    let result = true
    const variants = item.variants
    const option = `option${position}`

    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i]

      if (variant[`${option}`] === value) {
        result = false
      }
    }

    return result
  }

  useEffect(() => {
    let sku = false

    for (let i = 0; i < item.variants.length; i++) {
      const variant = item.variants[i]
      if (
        variant.option1 === option1 && 
        variant.option2 === option2 && 
        variant.option3 === option3
      ) {
        sku = variant
        setVariantIndexSelected(i)
      }
    }

    if (sku) {
      setVariantSelected(sku)
    }
  }, [option1, option2, option3])

  return (
    <div className='pl-variant-selector'>

      {item.options.map((option, index) => {
          return (
            <div key={index} className='pl-variant-selector__option'>
              <div className='pl-variant-selector__option--head'>{option.name}</div>
              <div className='pl-variant-selector__option--items'>
                {option.values.map((value, i) => {
                  return (
                    <div key={i} 
                      onClick={isAvailable(value, option.position) ? noAdd : selVariant}
                      data-option={value}
                      data-option-num={option.position}
                      className={`
                        pl-variant-selector__option--items__item ${value === variantSelected.option1 || value === variantSelected.option2 ? 'active' : ''} ${isAvailable(value, option.position) ? 'disable' : ''}
                      `}
                    >
                      {value}
                    </div>
                  )
                })}
              </div>
            </div>
          )
      })}

    </div>
  )
}
export default VariantSelector