import React from 'react'

function Blog() {

  return (
    <div className='content-for-layout'>
      Blog
    </div>
  )
}
export default Blog