import React from 'react'

function Account({
  section
}) {

  return (
    <div className='content-for-layout'>
      Account - {section}
    </div>
  )
}
export default Account