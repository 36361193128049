import React from 'react'

import CartItem from '../snippets/CartItem'

function CheckoutSummary({
  cart,
  minimal
}) {

  return (
    <div className='blA7b'>

      <div className='_1ip0g651'>
        <div aria-labelledby="ResourceList4" className='_6zbcq51b'>
          {cart.items.map((item, index) => {
            return (
              <CartItem key={index} product={item} minimal={minimal} />
            )
          })}
        </div>
      </div>
      
      <table>
        <tr>
          <td>Subtotal</td>
          <td>$24.95</td>
        </tr>
        <tr>
          <td>Shipping</td>
          <td>FREE</td>
        </tr>
        <tr>
          <td>Estimated taxes</td>
          <td>$4.74</td>
        </tr>
        <tr>
          <td>Total</td>
          <td><span>COP</span>$29.69</td>
        </tr>
      </table>
    </div>
  )
}
export default CheckoutSummary