import React from 'react'

function CheckoutInput({
  index,
  label,
  type
}) {

  return (
    <div className={`_1fragem3w _1fragem69 _1fragemo1 _1fragem3c _10vrn9p1 _10vrn9p0 _10vrn9p4 _1fragempt ${type === 'text' ? '_7ozb2u2' : ''}`}>
      <div className='_1fragemo1'>
        <label 
          id={`TextField${index}-label`} 
          for={`TextField${index}`} 
          class="cektnc3 _1fragemnr _1fragemvb _1fragemuu _1fragemw2 _1fragemvp _1fragemvk _1fragemvy _1fragemvz"
        >
          <span class="cektnc9">
            <span class="rermvf1 _1fragemuo _1fragemuq _1fragem2i">{label}</span>
          </span>
        </label>
        <div class="_7ozb2u6 _1fragemo1 _1fragem3c _1fragemq3 _1fragemvp _1fragemvk _1fragemvy _1fragemw1 _1fragempt _1fragemw8 _7ozb2ul _7ozb2uh">
          <input 
            id={`TextField${index}`} 
            name="firstName" 
            placeholder={label} 
            type="text" 
            aria-required="false" 
            aria-labelledby={`TextField${index}-label`} 
            class="_7ozb2uq _1fragemo1 _1fragemw2 _1fragemqz _1fragemva _7ozb2ur _7ozb2u11 _7ozb2u1h" />
        </div>
      </div>
    </div>
  )
}
export default CheckoutInput