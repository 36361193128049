import React from 'react'
import PageHead from '../sections/PageHead'
import Container from '../layout/Container'

function Page({
  context
}) {

  return (
    <div className='content-for-layout'>

      <PageHead
        title={context.page_name}
      />

      <div className='shopify-section section page-section page-section-content'>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: context.page_content }} />
        </Container>
      </div>
    </div>
  )
}
export default Page