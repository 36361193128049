import React from 'react'
import Container from '../layout/Container'
import VerticalCenter from './../snippets/VerticalCenter'

function Footer() {

  return (
    <div id="shopify-section-sections--16424259223640__footer" className='shopify-section section-footer'>
      <Container>
        <footer>
          <VerticalCenter>
            © 2024, Permalink Technologies <a href="https://www.shopify.com/?utm_campaign=poweredby&utm_medium=shopify&utm_source=onlinestore">Powered by Shopify</a>
          </VerticalCenter>
        </footer>
      </Container>
    </div>
  )
}
export default Footer