import React from 'react'

function Notfound() {

  return (
    <div className='content-for-layout'>
      404
    </div>
  )
}
export default Notfound