import React, { useState, useEffect } from 'react'

function Accordeon({id, children}) {
  const [show, setShow] = useState(false)

  const toggler = () => {
    setShow(!show)
  }

  useEffect(() => {
    if (show) {
      const content = document.querySelector(`#acc_${id} .U6oc8`);
      content.style.maxHeight = content.scrollHeight + "px";
    } else {
      const content = document.querySelector(`#acc_${id} .U6oc8`);
      content.style.maxHeight = null;
    }
  }, [show])

  return (
    <div id={`acc_${id}`} className={`U6oc6 ${show ? 'active' : ''}`}>
      <button className='U6oc7' onClick={toggler}>
        <div className='_1fragempsC'>
          {!show
            ? 'Show order summary'
            : 'Hide order summary'
          }
        </div>
      </button>
      <div className="U6oc8">
        <div className='_1fragempsC'>
          {children}
        </div>
      </div>
    </div>
  )
}
export default Accordeon