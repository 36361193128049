import React from 'react'
import Container from '../layout/Container'
import VerticalCenter from '../snippets/VerticalCenter'

function PageHead({
  title
}) {

  return (
    <div 
      id="shopify-section-template--16424281210968__page" 
      className={`shopify-section section page-section page-section-head`}
    >
      <VerticalCenter>
        <Container>
          Titulo de pagina - {title}
        </Container>
      </VerticalCenter>
    </div>
  )
}
export default PageHead