import React from 'react'

function VerticalCenter({children}) {
  return (
    <div className="pl-rely">
      <div className="pl-centry">
        {children}
      </div>
    </div>
  )
}

export default VerticalCenter