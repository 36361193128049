import React, { useState } from 'react'

import Preloader from './Preloader'
import VerticalCenter from './VerticalCenter'
import Icon from './Icon'

import { getPrice, updateFromCart, deleteFromCart } from '../../utils/functions'

function CartItem({
  product,
  minimal
}) {
  const [itemUpdating, setItemUpdating] = useState(false)

  const up = async (e) => {
    e.preventDefault()
    let c = product.quantity
    let f = c + 1

    setItemUpdating(true)

    setTimeout( () => {
      updateFromCart(product.variant_id, f)
      setItemUpdating(false)
    }, 1000 )
    
  }

  const down = async (e) => {
    e.preventDefault()
    let c = product.quantity
    let f = c - 1

    setItemUpdating(true)

    setTimeout( () => {
      if (f === 0) {
        deleteFromCart(product.variant_id)
      } else {
        updateFromCart(product.variant_id, f)
      }
      
      setItemUpdating(false)
    }, 1000 )
  }

  const remove = async (e) => {
    e.preventDefault()
    setItemUpdating(true)

    setTimeout( () => {
      deleteFromCart(product.variant_id)
      setItemUpdating(false)
    }, 1000 )
  }
  
  return (
    <div className={`pl-cart-item`}>
      <div className='pl-cart-item--image' style={{"backgroundImage": `url(/cdn/files/${product.featured_image.url})`}}>
        {minimal && <span>{product.quantity}</span>}
      </div>
      <div className='pl-cart-item--labels'>
        <div className='pl-cart-item--labels--name'>

          {minimal 
            ? <VerticalCenter>
                <span>{product.product_title}</span>
                <span>{product.product_description}</span>
              </VerticalCenter>
            : product.product_title
          }
        </div>
        <div className='pl-cart-item--labels--price'>{getPrice(product.price)}</div>
      </div>
      <div className='pl-cart-item--actions'>
        <div className='pl-cart-item--actions--qty'>
          <button onClick={down} className='slow_ani'>
            <Icon name="minus" />
          </button>
          <input type="number" readOnly={true} value={product.quantity} />
          <button onClick={up} className='slow_ani'>
            <Icon name="plus" />
          </button>
        </div>
      </div>

      <div className='pl-cart-item--delete' onClick={remove}>
        <Icon name="remove" />
      </div>
      <div className='pl-cart-item--total'>
        {getPrice(product.price * product.quantity)}
      </div>

      {itemUpdating &&
        <div className='pl-cart-item--overlay'>
          <VerticalCenter>
            <Preloader
              type="section"
              invert
            />
          </VerticalCenter>
        </div>
      }
    </div>
  )
}

export default CartItem