import React from 'react'

function Image({
  src,
  src_m,
  alt
}) {

  return (
    <picture>
      <source media="(max-width: 991px)" srcset={src_m} />
      <source media="(min-width: 992px)" srcset={src} />
      <img src={src} alt={alt} />
    </picture>
  )
}

export default Image