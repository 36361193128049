import React, { useEffect, useState } from 'react'
import Container from '../layout/Container'
import VerticalCenter from './../snippets/VerticalCenter'
import MiniCart from '../snippets/MiniCart'

import { getCart } from '../../utils/functions'

function Header() {
  const [cart, setCart] = useState(false)

  useEffect(() => {
    function get() {
      const cart = getCart()
      if (cart && cart.items) {
        setCart(cart)
      }
    }
    get()

    window.addEventListener('cartUpdate', function() {
      get()
    })
  }, [])


  return (
    <div id="shopify-section-sections--16424259256408__header" className='shopify-section section-header'>
      <Container>
        <header>
          <div className='section-header-logo'>
            <VerticalCenter>
              <span className='company-name'>
                <a href="/">SiteOne</a>
              </span>
            </VerticalCenter>
          </div>


          <div className='section-header-icons'>
            <MiniCart cart={cart} />
          </div>
        </header>
      </Container>
    </div>
  )
}
export default Header