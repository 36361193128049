window.Shopify = function() {
  const AdminBarInjector = function(t) {
    return null
  }
  const CountryProvinceSelector = function(country_domid,province_domid,options) {
    return null
  }
  const PaymentButton = function() {
    return null
  }
  const addListener = function(target,eventName,callback) {
    return null
  }
  const analytics = function(t) {
    return null
  }
  const autoloadFeatures = function(t) {
    return null
  }
  const bind = function(fn,scope) {
    return null
  }
  const captcha = function(t) {
    return null
  }
  const dm = function(t) {
    const sp = function () {
      const catalog = function() {

        return [
          {
            "collection_name": "Colleccion 1",
            "collection_handle": "collection-1",
            "collection_products": [
              {
                "product_name": "Product 1",
                "product_handle": "product-1",
                "product_image": "product.png",
                "id": 8018426855512,
                "vendor": "Hydrogen Vendor",
                "product_type": "snowboard",
                "tags": "Accessory, Sport, Winter",
                "variants": [
                  {
                    "id": 41990799589464,
                    "product_id": 8018426855512,
                    "title": "Verde / S",
                    "price": 456,
                    "sku": "",
                    "position": 1,
                    "compare_at_price": "",
                    "option1": "Verde",
                    "option2": "S",
                    "option3": null
                  },
                  {
                    "id": 41990799589463,
                    "product_id": 8018426855512,
                    "title": "Verde / M",
                    "price": 456,
                    "sku": "",
                    "position": 1,
                    "compare_at_price": "",
                    "option1": "Verde",
                    "option2": "M",
                    "option3": null
                  }
                ],
                "options": [
                  {
                    "name": "Color",
                    "position": 1,
                    "values": [
                      "Verde", "Rojo"
                    ]
                  },
                  {
                    "name": "Talla",
                    "position": 2,
                    "values": [
                      "S", "M", "L"
                    ]
                  }
                ],
                "images": [
                  {
                    "alt": "Image",
                    "src": "product.png",
                    "variant_ids": []
                  }
                ]
              },
              {
                "product_name": "Product 3",
                "product_handle": "product-3",
                "product_image": "product.png",
                "id": 8018426855513,
                "vendor": "Hydrogen Vendor",
                "product_type": "snowboard",
                "tags": "Accessory, Sport, Winter",
                "variants": [
                  {
                    "id": 41990799589465,
                    "product_id": 8018426855513,
                    "title": "Rojo / S",
                    "price": 1500,
                    "sku": "",
                    "position": 1,
                    "compare_at_price": "",
                    "option1": "Rojo",
                    "option2": "S",
                    "option3": null
                  },
                  {
                    "id": 41990799589466,
                    "product_id": 8018426855513,
                    "title": "Rojo / M",
                    "price": 1400,
                    "sku": "",
                    "position": 1,
                    "compare_at_price": "",
                    "option1": "Rojo",
                    "option2": "M",
                    "option3": null
                  }
                ],
                "options": [
                  {
                    "name": "Color",
                    "position": 1,
                    "values": [
                      "Verde", "Rojo"
                    ]
                  },
                  {
                    "name": "Talla",
                    "position": 2,
                    "values": [
                      "S", "M", "L"
                    ]
                  }
                ],
                "images": [
                  {
                    "alt": "Image",
                    "src": "product.png",
                    "variant_ids": []
                  }
                ]
              }
            ]
          }
        ]
      }

      const pages = function() {

        return [
          {
            "page_name": "About Us",
            "page_handle": "about-us",
            "page_content": "<strong>Hola mundo</strong> - Este es el contenido HTML de una pagina."
          }
        ]
      }

      return {
        catalog,
        pages
      }
    }

    return {
      sp
    }
  }
  // Shopify.dm().sp().catalog()
  const init = function(t) {
    document.addEventListener("DOMContentLoaded", function() {
      let actualScroll = 0

      window.addEventListener('scroll',() => {
        const header = document.querySelector('.section-header')

        if (header) {
          const top = Math.min(-(window.scrollY - actualScroll + header.clientHeight), 0)
          if (window.scrollY  > actualScroll) {
            actualScroll = window.scrollY
          }
          if (top === 0) {
            actualScroll = window.scrollY + header.clientHeight
          }
          header.setAttribute("style", `--_top:${top}px`);
          header.classList.toggle('active',window.scrollY < actualScroll)
          header.classList.toggle('clr',window.scrollY > header.clientHeight * 1.5)
        }

      })
    })
  }

  const reactive = function() {
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: {
        message: 'Cart is updated!'
      }
    }))
  }

  const cdnHost = "permalink-boilerplate-store.myshopify.com/cdn"
  const ce_forms = {
    q: []
  }
  const country = "CO"
  const currency = {
    active: "COP",
    rate: "1.0"
  }
  const featureAssets = {
    "shop-jf": {}
  }
  const locale = "en"
  const modules = true

  const shop = "permalink-boilerplate-store.myshopify.com"
  const theme = {
    handle: "null",
    id: 132004446296,
    name: "Permalink",
    role: "main"
  }
 
  return {
    AdminBarInjector,
    CountryProvinceSelector,
    PaymentButton,
    addListener,
    analytics,
    autoloadFeatures,
    bind,
    captcha,
    dm,
    reactive,
    shop,
    theme,
    cdnHost,
    ce_forms,
    country,
    currency,
    featureAssets,
    locale,
    modules,
    init
  }
}()
window.Shopify.init()