import React from 'react'

function Button({
  text,
  loading,
  status,
  onClick,
  link
}) {

  const handler = () => {

    if (status === 'active' && !loading) {
      link 
        ? window.location.href = link
        :  onClick()
    }
  }

  return (
    <button 
      className={`pl-global-button U6obc1--${status}`}
      onClick={handler}
    >
      {loading
        ? <img src='/cdn/files/preloader.gif' alt='loader' />
        : text
      }
    </button>
  )
}
export default Button