import React from 'react'
import { useParams } from 'react-router-dom'

function Article() {
  const { handle }= useParams()

  return (
    <div className='content-for-layout'>
      Article - {handle}
    </div>
  )
}
export default Article