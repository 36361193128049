import React from 'react'
import Container from '../layout/Container'
import CardProduct from '../snippets/CardProduct'

function ProductsGrid({
  title,
  subtitle,
  collection,
  isCollection
}) {
  const selected = window.Shopify.dm().sp().catalog().find((item) => item.collection_handle === collection)

  return (
    <div 
      id="shopify-section-template--16424281210968__featured_collection" 
      className={`shopify-section section`}
    >
      <div className='collection section-template--16424281210968__featured_collection-padding'>
        <Container>
          {title &&
            <div className='collection__title title-wrapper'>
              <h2>{title}</h2>
            </div>
          }
          {subtitle &&
            <div className='rich-text__text'>
              <p>{subtitle}</p>
            </div>
          }
          
          <div className='page-width-desktop'>
            <ul 
              id='featured_collection' 
              className='grid product-grid contains-card contains-card--product'
            >
              {selected.collection_products.map((item, index) => {
                console.log("prod:", item.variants)

                return (
                  <CardProduct key={index} index={index} item={item} />
                )
              })}
            </ul>
          </div>
          
          {!isCollection &&
            <div className='collection__view-all'>
              <a href={`/collections/${selected.collection_handle}`}>Ver más</a>
            </div>
          }
        </Container>
      </div>
    </div>
  )
}

export default ProductsGrid