import Swal from "sweetalert2";

/**
 * Constants
 */
const initialData = {
  loggedIn: false,
  fetching: false,
  token: "",
  error: ""
};
const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_ERROR = "LOGIN_ERROR";

const LOG_OUT = "LOG_OUT";

/**
 * Reducer
 */
const reducer = (state = initialData, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, fetching: true };
    case LOGIN_SUCCESS:
      return { ...state, fetching: false, ...action.payload, loggedIn: true, error: "" };
    case LOGIN_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case LOG_OUT:
      return { ...initialData, loggedIn: false, fetching: false };
    default:
      return state;
  }
}
export default reducer

export const logOutAction = () => (dispatch) => {
  dispatch({
    type: LOG_OUT,
  });
  localStorage.removeItem("storage");
  localStorage.removeItem("_grecaptcha")
  window.location.href = '/'
}

export const logOutIdleAction = () => (dispatch) => {
  dispatch({
    type: LOG_OUT,
  });
  localStorage.removeItem("storage");
  Swal.fire({
    icon: 'info',
    // title: 'Oops...',
    confirmButtonText: 'Aceptar',
    text: 'Su sesión ha caducado por inactividad durante varios minutos.',
  })
}

export const restoreSessionAction = () => (dispatch) => {
  let storage = localStorage.getItem("storage")
  storage = JSON.parse(storage)

  if (storage && storage.user.loggedIn) {
    
    dispatch({
      type: LOGIN_SUCCESS,
      payload: storage.user
    })
  }
}

export const loginAction = () => async (dispatch, getState) => {

  dispatch({
    type: LOGIN,
  })
  try {
    // const verify = await postVerify(gToken, credentials.document)
    const verify = {
      success: true
    }

    if (verify && verify.success === true) {
      const user = {}

      if (user && user.error && user.error === 'User not found') {
        localStorage.setItem('userNotFound', 'true')
        window.location.reload()
      }
      
      if (user && user.accessToken) {
        setTimeout( () => {
          localStorage.removeItem('userNotFound')
        }, 2000 )
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token: user.accessToken
          }
        })
        saveStorage(getState())

      } else {

        dispatch({
          type: LOGIN_ERROR,
          payload: user.message
        })
      }

    } else {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          verify
        }
      })
    }

  } catch (e) {

    dispatch({
      type: LOGIN_ERROR,
      payload: e.message
    })
  }
}

export const loginShaAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOGIN,
  })
  try {
    const user = {}

    if (user && user.message && user.message === 'User Not found.') {
      localStorage.setItem('userNotFound', 'true')
    }
    
    if (user && user.accessToken) {

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: user.accessToken
        }
      });
      saveStorage(getState())

      return true
    } else {

      dispatch({
        type: LOGIN_ERROR,
        payload: user.message
      })
      return false
    }
  } catch (e) {

    dispatch({
      type: LOGIN_ERROR,
      payload: e.message
    })
    return false
  }
}

/**
 * Aux Functions
 */
const saveStorage = (storage) => {
  localStorage.storage = JSON.stringify(storage)
}