import React from 'react'

function BreadCrumb({
  linkText,
  linkUrl,
  currentText
}) {

  return (
    <div className='breadcrumb-container'>
      <a href={'/'}>
        Home
      </a>
      <div className='breadcrumb-container--separator'>/</div>
      <a href={linkUrl}>
        {linkText}
      </a>
      <div className='breadcrumb-container--separator'>/</div>
      <span>
        {currentText}
      </span>
    </div>
  )
}
export default BreadCrumb