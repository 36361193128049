import React from 'react'
import Slider from "react-slick"
import Image from '../snippets/Image'

function SliderRotator({
  mobileItemsToShow,
  desktopItemsToShow,
  list
}) {

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  }

  return (
    <div 
      id="shopify-section-template--16424281210968__slider_rotator" 
      className={`shopify-section section`}
    >
      <div className={`slider-rotator`}>
        <Slider {...settings}>
          {list.map((slide, index) => {
            console.log("slide:", slide)

            return (
              <div key={index} className={`slider-rotator__slide slide-type-${slide.type}`}>
                {slide.type === 'image' &&
                  slide.link
                    ? <a href={slide.link}>
                        <Image
                          src={`/cdn/files/${slide.image}`}
                          src_m={`/cdn/files/${slide.imabeMobile}`}
                          alt={slide.id}
                        />
                      </a>
                    : <Image
                        src={`/cdn/files/${slide.image}`}
                        src_m={`/cdn/files/${slide.imabeMobile}`}
                        alt={slide.id}
                      />
                }
              </div>
            )
          })}
        </Slider>
      </div>
    </div>

  )
}

export default SliderRotator