import React, { useState, useEffect } from 'react'

import Container from '../layout/Container'
import CartItem from '../snippets/CartItem'

import { getCart } from '../../utils/functions'

function Cart() {
  const [cart, setCart] = useState(false)

  useEffect(() => {
    function get() {
      const cart = getCart()
      if (cart) {
        setCart(cart)
      }
    }
    get()

    window.addEventListener('cartUpdate', function() {
      get()
    })
  }, [])

  console.log("Cart:", cart)

  return (
    <div id="MainContent" className='content-for-layout'>
      <div id="shopify-section-template--16424258699352__cart-items" className='shopify-section'>
        <div className='cart-items'>
          <Container>
            <div className='title-wrapper-with-link'>
              <h1 className='title title--primary'>Your cart</h1>
              <a href="/collections/all">Continue shopping</a>
            </div>
            {(cart && cart.items.length) === 0
              ? <div className='cart__warnings'>
                  <h1 className='cart__empty-text'>Your cart is empty</h1>
                  <a href="/collections/all">Continue shopping</a>
                </div>
              : <form id="cart" action="/cart" className='cart__contents' method='post'>
                  <div className='cart__header'>
                    <div>Product</div>
                    <div>Quantity</div>
                    <div>Total</div>
                  </div>
                  <div className='cart__items'>
                    {cart &&
                      <div className='cart-items'>
                        {cart.items.map((item, index) => {
                          return (
                            <CartItem key={index} product={item} />
                          )
                        })}
                      </div>
                    }
                  </div>
                </form>
            }
            
            
          </Container>
        </div>
      </div>
    </div>
  )
}
export default Cart