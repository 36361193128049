import React from 'react'

import VerticalCenter from './../snippets/VerticalCenter'
import Container from '../layout/Container'

function Columns({
  desktop,
  mobile,
  height,
  children
}) {

  return (
    <div 
      id="shopify-section-template--16424281210968__text_image" 
      className={`shopify-section section image-text-section`}
    >
      <Container>
        <div className={`
          pl-columns 
          pl-columns__col-desk-${desktop} 
          pl-columns__col-mobile-${mobile}`}
          style={{"height": `${height ? `${height}px` : 'auto'}`}}
        >
          {children.map((child) => {
            return child
          })}
        </div>
      </Container>
    </div>

  )
}
export default Columns

export function Col({children}) {

  return (
    <div className='pl-columns__item'>
      <VerticalCenter>
        {children}
      </VerticalCenter>
    </div>
  )
}