import React, { useEffect, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation
} from 'react-router-dom'
import './utils/shopify'

// pages
import Home from "./components/pages/Home"
import Collection from "./components/pages/Collection"
import Product from "./components/pages/Product"
import Page from "./components/pages/Page"
import Blog from "./components/pages/Blog"
import Article from "./components/pages/Article"
import Account from "./components/pages/Account"
import Cart from "./components/pages/Cart"
import Checkout from "./components/pages/Checkout"
import Thankyou from "./components/pages/Thankyou"
import Notfound from "./components/pages/Notfound"

// sections
import Header from "./components/sections/Header"
import Footer from "./components/sections/Footer"

import { getAllProducts } from './utils/functions'

import './styles/main.scss'

export const StateController = createContext(null)

function App() {
  const location = useLocation()
  const collectionRouters = window.Shopify.dm().sp().catalog()
  const pagesRouters = window.Shopify.dm().sp().pages()

  useEffect(() => {
    const cart = localStorage.getItem('cart')

    if (!cart) {
      localStorage.setItem('cart', JSON.stringify({
        attributes: {},
        currency: "COP",
        items: [],
        note: null,
        requires_shipping: true,
        token: "169efa1a0a4ed07e424dd5b7c2fad128"
      }))
    }
  }, [])

  return (
    <StateController.Provider
      value = {
        {}
      }
    >
      {!location.pathname.includes('checkouts') &&
        <Header />
      }
      <Routes>
        <Route path="/" element={<Home />} />

        {collectionRouters.map((page, i) => {
          return (
            <Route key={i} path={`/collections/${page.collection_handle}`} element={<Collection />} />
          )
        })}
        {getAllProducts(collectionRouters).map((page, i) => {
          return (
            <Route key={i} path={`/products/${page.product_handle}`} element={<Product />} />
          )
        })}
        {pagesRouters.map((page, i) => {
          return (
            <Route key={i} path={`/pages/${page.page_handle}`} element={<Page context={page} />} />
          )
        })}

        <Route path="/blogs/posts" element={<Blog />} />
        <Route path="/blogs/posts/:handle" element={<Article />} />
        <Route path="/account/login" element={<Account section={"login"} />} />
        <Route path="/account/recover" element={<Account section={"recover"} />} />
        <Route path="/account/register" element={<Account section={"register"} />} />
        <Route path="/cart" element={<Cart />} />

        <Route path="/checkouts/cn/:id" element={<Checkout />} />
        <Route path="/:id/checkouts/:subid/" element={<Checkout />} />
        
        <Route path="/:id/checkouts/:subid/thank-you" element={<Thankyou />} />
        <Route path="/checkouts/cn/:id/thank-you" element={<Thankyou />} />
        <Route path="/*" element={<Notfound />} />
      </Routes>

      {!location.pathname.includes('checkouts') &&
        <Footer />
      }
    </StateController.Provider>
  )
}

export default App