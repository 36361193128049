import React from 'react'
import { useParams } from 'react-router-dom'

function Thankyou() {
  const { id, subid }= useParams()

  return (
    <div className='content-for-layout'>
      Thankyou - {id} -- {subid}
    </div>
  )
}
export default Thankyou