import React, { useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

import VariantSelector from './VariantSelector'
import Button from './Button'

import { getPrice, addToCart } from '../../utils/functions'

function CardProduct({
  index,
  item
}) {
  const [variantIndexSelected, setVariantIndexSelected] = useState(0)
  const [variantSelected, setVariantSelected] = useState(false)
  const [loading, setLoading] = useState(false)

  const add = () => {
    setLoading(true)

    addToCart({
      id: new Date().valueOf(),
      final_price: variantSelected.price,
      featured_image: {
        url: "product.png"
      },
      price: variantSelected.price,
      product_title: "Product title",
      product_description: "hola mundo",
      product_id: variantSelected.product_id,
      variant_id: variantSelected.id,
      quantity: 1,
      properties: {},
      sku: "NATN",
      total_discount: 0,
      variant_title: "Default",
      vendor: "Permalink"
    })
    window.Shopify.reactive()

    setTimeout( () => {
      setLoading(false)
    }, 1000 )
  }

  return (
    <li 
      id="featured_collection-1"
      className='grid__item scroll-trigger animate--slide-in'
    >
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true} key={index}>
        <div className='card-wrapper product-card-wrapper'>
          <div className='card card--standard'>
            <div className='card__inner'>
              <a href="/#">
                <div>
                  <img src={`/cdn/files/${item.product_image}`} />
                </div>
              </a>
            </div>
            <div className='card__content'>
              <div className='card__information'>
                <h3 className='card__heading h5'>
                  <a href={`/products/${item.product_handle}`}>
                    {item.product_name}
                  </a>
                </h3>
                <div className="price">
                  {getPrice(item.variants[variantIndexSelected].price)}
                </div>
              </div>
              <div className='card__actions'>
                <VariantSelector
                  item={item}
                  variantIndexSelected={variantIndexSelected}
                  setVariantIndexSelected={setVariantIndexSelected}
                  variantSelected={variantSelected}
                  setVariantSelected={setVariantSelected}
                />

                <Button
                  text="Agregar al carrito"
                  loading={loading}
                  status="active"
                  onClick={add}
                />
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </li>
  )
}
export default CardProduct