export const getAllProducts = function(data) {
  const products = []

  for (let i = 0; i < data.length; i++) {
    const collection = data[i]
    
    for (let p = 0; p < collection.collection_products.length; p++) {
      products.push(collection.collection_products[p])
    }
  }

  return products
}

export const getCollection = function(catalog, handle) {
  let collection = null
  for (let i = 0; i < catalog.length; i++) {
    const item = catalog[i]

    for (let p = 0; p < item.collection_products.length; p++) {
      if (item.collection_products[p].product_handle === handle) {
        collection = catalog[i]
      }
    }
  }

  return collection
}

export const getPrice = function(data) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return formatter.format(data).replace(',','.')
}

export const getCart = function() {
  const curCart = localStorage.getItem('cart')
  let returner = {}
  if (curCart) {
    returner = JSON.parse(curCart)
  }

  return returner
}

export const addToCart = async function(item) {
  const curCart = localStorage.getItem('cart')
  let returner = ""
  if (curCart) {
    const obj = JSON.parse(curCart)
    const curItems = obj.items
    let target = 'add'

    for (let i = 0; i < curItems.length; i++) {
      const el = curItems[i]
      if (el.variant_id === item.variant_id) {
        target = 'update'
        curItems[i].quantity = curItems[i].quantity + item.quantity
        obj.items = curItems
        localStorage.setItem('cart', JSON.stringify(obj))
        returner = 'Updated!'
      }
    }

    if (target === 'add') {
      curItems.push(item)
      obj.items = curItems
      localStorage.setItem('cart', JSON.stringify(obj))
      returner = 'Added!'
    }
  } else {
    returner = 'Cart not found.'
  }

  window.Shopify.reactive()
  return returner
}

export const updateFromCart = function(id, qty) {
  const curCart = localStorage.getItem('cart')
  let returner = false
  if (curCart) {
    const obj = JSON.parse(curCart)
    const curItems = obj.items

    for (let i = 0; i < curItems.length; i++) {
      const el = curItems[i]
      if (el.variant_id === id) {
        curItems[i].quantity = qty
        obj.items = curItems
        localStorage.setItem('cart', JSON.stringify(obj))
        returner = 'Updated!'
      }
    }
  }

  window.Shopify.reactive()
  return returner
}

export const deleteFromCart = function(id) {
  const curCart = localStorage.getItem('cart')
  let returner = false

  if (curCart) {
    const obj = JSON.parse(curCart)
    const curItems = obj.items

    for (let i = 0; i < curItems.length; i++) {
      const el = curItems[i]
      if (el.variant_id === id) {
        curItems.splice(i, 1)
        obj.items = curItems
        localStorage.setItem('cart', JSON.stringify(obj))
        returner = 'Deleted!'
      }
    }
  }

  window.Shopify.reactive()
  return returner
}

export const getCartCount = function(items) {
  let count = 0
  for (let i = 0; i < items.length; i++) {
    count = count + items[i].quantity
  }

  return count
}

export const getCartTotal = function(cart) {
  let total = 0

  if (cart && cart.items) {
    for (let i = 0; i < cart.items.length; i++) {
      total = total + (cart.items[i].price * cart.items[i].quantity)
    }
  }

  return total
}

export const getCartToken = function(cart) {

  return cart.token
}