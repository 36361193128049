import React from 'react'
import ProductsGrid from '../sections/ProductsGrid'
import SliderRotator from '../sections/SliderRotator'
import Columns, { Col } from '../sections/Columns'

function Home() {

  return (
    <div id="MainContent" className='content-for-layout'>

      <SliderRotator
        mobileItemsToShow={1}
        desktopItemsToShow={1}
        list={
          [
            {
              id: 1,
              type: "image",
              image: "banner.png",
              imabeMobile: "banner_m.png",
              link: "/pages/about-us"
            },
            {
              id: 2,
              type: "image",
              image: "banner.png",
              imabeMobile: "banner_m.png",
              link: "/pages/about-us"
            },
            {
              id: 3,
              type: "image",
              image: "banner.png",
              imabeMobile: "banner_m.png",
              link: "/pages/about-us"
            }
          ]
        }
      />

      <ProductsGrid 
        title="Featured products"
        titleAlign="left"
        subtitle="Subtitulo aqui"
        subtitleAlign="left"
        collection="collection-1"
      />

      <Columns
        desktop={2}
        mobile={1}
        height={200}
      >
        <Col>
        <h2>asdfsd</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non nunc a orci aliquam congue sit amet id risus.</p>
        </Col>
        <Col>
          <img src="https://via.placeholder.com/490x360" alt="" />
        </Col>
      </Columns>
      
    </div>
  )
}
export default Home