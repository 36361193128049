import React from 'react'

function Container({
  children, 
  className
}) {
  return (
    <div className={`page-width ${className ? className : ''}`}>
      {children}
    </div>
  )
}

export default Container