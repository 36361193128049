import React from 'react'

function CheckoutCheckbox({
  label,
  className
}) {

  return (
    <div className='_1ip0g651 _1fragemo1 _1fragem5p _1fragem82 _1fragem3c'>
      <label className={`_1mmswk9g ${className}`}>
        <input type="checkbox"/>
        <div className="_1mmswk9g__checkmark"></div>
        <div className="_1mmswk9g__body">{label}</div>
      </label>
    </div>
  )
}
export default CheckoutCheckbox