import React from 'react'

function CheckoutContainer({
  children
}) {
  return (
    <div className={`_1fragempsC`}>
      {children}
    </div>
  )
}

export default CheckoutContainer