import React from 'react'
import { useLocation } from 'react-router-dom'

import Container from '../layout/Container'
import ProductsGrid from '../sections/ProductsGrid'

function Collection() {
  const location = useLocation()
  const { pathname } = location
  const handle = pathname.split('/collections/')
  const selected = window.Shopify.dm().sp().catalog().find((item) => item.collection_handle === handle[1])

  return (
    <div id="MainContent" className='content-for-layout'>
      <div id="shopify-section-template--16424258732120__banner" className='shopify-section section collection-banner'>
        <div className='collection-hero'>
          <Container className='collection-hero__inner'>
            <div className='collection-hero__text-wrapper'>
              <h1 className='collection-hero__title'>
                 {selected.collection_name}
              </h1>
            </div>
          </Container>
        </div>
      </div>
      <div id="shopify-section-template--16424258732120__product-grid" className='shopify-section section collection-grid'>
        <div>filters - {selected.collection_products.length} products</div>
        <div className='product-grid-container'>
          <div className='collection'>
            <ProductsGrid 
              collection={selected.collection_handle}
              isCollection={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Collection